import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'
import storage from 'store'
import store from './store'
import { ACCESS_TOKEN, USER_INFO, ADD_KEEP_ALIVE, CLEAR_KEEP_ALIVE, CITY } from '@/store/mutation-types'

Vue.use(Router)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    // meta: {
    //   title: '首页',
    //   keepAlive: true
    // },
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import(`./view/index_${process.env.VUE_APP_BUILD_TARGET}.vue`),
        meta: {
          title: '兑换区',
          keepAlive: true,
          hasTabbar: true
        }
      },
      {
        path: '/location',
        name: 'location',
        component: () => import(`./view/location_${process.env.VUE_APP_BUILD_TARGET}.vue`),
        meta: {
          title: '选择定位',
          keepAlive: false,
        },
      },
      {
        path: '/login',
        name: 'login',
        component: () => import(`./view/login_${process.env.VUE_APP_BUILD_TARGET}.vue`),
        meta: {
          title: '登录',
          keepAlive: true,
          isPublic: true,
        },
      },
      {
        path: '/agreement',
        name: 'agreement',
        component: () => import(`./view/agreement_${process.env.VUE_APP_BUILD_TARGET}.vue`),
        meta: {
          title: '个人信息保护政策',
          keepAlive: false,
          isPublic: true,
        },
      },
      {
        path: '/user-agreement',
        name: 'userAgreement',
        component: () => import(`./view/user_agreement_${process.env.VUE_APP_BUILD_TARGET}.vue`),
        meta: {
          title: '用户协议',
          keepAlive: false,
          isPublic: true,
        },
      },
      {
        path: '/search',
        name: 'search',
        component: () => import('./view/search.vue'),
        meta: {
          title: '搜索',
          keepAlive: false,
        },
      },
      {
        path: '/goods',
        name: 'goods',
        component: () => import('./view/goods/list.vue'),
        meta: {
          title: '全部商品',
          keepAlive: false,
        },
      },
      {
        path: '/goods-detail/:id',
        name: 'goodsDetail',
        component: () => import('./view/goods/detail.vue'),
        meta: {
          title: '商品详情',
          keepAlive: false,
        },
      },
      // coupon-list
      {
        path: '/coupon-list',
        name: 'coupon-list',
        component: () => import('./view/coupon/list.vue'),
        meta: {
          title: '兑换记录',
          keepAlive: false,
        },
      },
      {
        path: '/coupon-items',
        name: 'coupon-items',
        component: () => import('./view/mall/coupon/coupon-items.vue'),
        meta: {
          title: '满减专区',
          keepAlive: true,
        },
      },
      
      {
        path: '/in-kind/:id',
        name: 'in-kind',
        component: () => import('./view/coupon/in-kind.vue'),
        meta: {
          title: '商品到家',
          keepAlive: false,
        },
      },
      {
        path: '/in-kind-v2/:id',
        name: 'in-kind-v2',
        component: () => import('./view/coupon/in-kind-v2.vue'),
        meta: {
          title: '确认订单',
          keepAlive: false,
        },
      },
      {
        path: '/exchange-success/:id',
        name: 'exchange-success',
        component: () => import('./view/coupon/exchange-success.vue'),
        meta: {
          title: '兑换成功',
          keepAlive: false,
        },
      },
      {
        path: '/coupon-detail/:id',
        name: 'coupon-detail',
        component: () => import('./view/coupon/detail.vue'),
        meta: {
          title: '权益详情',
          keepAlive: false,
        },
      },
      {
        path: '/coupon-success',
        name: 'coupon-success',
        component: () => import('./view/coupon/success.vue'),
        meta: {
          title: '兑换成功',
          keepAlive: false,
        },
      },
      {
        path: '/address-list',
        name: 'address-list',
        component: () => import('./view/address/list.vue'),
        meta: {
          title: '收货地址',
          keepAlive: false,
        },
      },
      {
        path: '/address-add',
        name: 'address-add',
        component: () => import('./view/address/edit.vue'),
        meta: {
          title: '新增收货地址',
          keepAlive: false,
        },
      },
      {
        path: '/address-edit/:id',
        name: 'address-edit',
        component: () => import('./view/address/edit.vue'),
        meta: {
          title: '编辑收货地址',
          keepAlive: false,
        },
      },
      {
        path: '/policy',
        name: 'policy',
        component: () => import('./view/policy.vue'),
        meta: {
          title: '隐私政策',
          keepAlive: false,
          isPublic: true,
        },
      },
      {
        path: '/order-list',
        name: 'order-list',
        component: () => import('./view/order/list.vue'),
        meta: {
          title: '订单列表',
          keepAlive: false,
        },
      },
      {
        path: '/order-detail/:id',
        name: 'order-detail',
        component: () => import('./view/order/detail.vue'),
        meta: {
          title: '权益详情',
          keepAlive: false,
        },
      },
      {
        path: '/vip-detail',
        name: 'vip-detail',
        component: () => import('./view/vip-detail.vue'),
        meta: {
          title: '会员详情',
          keepAlive: false,
          hasTabbar: true,
        },
      },
      {
        path: '/mall/index',
        name: 'mall',
        component: () => import('./view/mall/index.vue'),
        meta: {
          title: '会员购',
          keepAlive: true,
          hasTabbar: true
        },
      },
      {
        path: '/mall/activity-rules',
        name: 'activity-rules',
        component: () => import('./view/mall/activity-rules.vue'),
        meta: {
          title: '活动规则',
          keepAlive: false
        },
      },
      {
        path: '/mall/item-search',
        name: 'item-search',
        component: () => import('./view/mall/item-search.vue'),
        meta: {
          title: '搜索',
          keepAlive: true
        },
      },
      {
        path: '/channel',
        name: 'channel',
        component: () => import('./view/channel/index.vue'),
        meta: {
          title: '会员购',
          keepAlive: true
        }
      },
      {
        path: '/coupon-index',
        name: 'coupon-index',
        component: () => import('./view/coupon-index/index.vue'),
        meta: {
          title: '',
          keepAlive: true
        }
      },
      {
        path: '/my',
        name: 'my',
        component: () => import('./view/my/index.vue'),
        meta: {
          title: '我的',
          keepAlive: false,
          hasTabbar: true
        }
      },
      {
        path: '/customer-service',
        name: 'customer-service',
        component: () => import('./view/my/customer-service.vue'),
        meta: {
          title: '客服中心',
          keepAlive: false,
          hasTabbar: false
        }
      },
      {
        path: '/mall/item-detail/:id',
        name: 'mall-item',
        component: () => import('./view/mall/item-detail.vue'),
        meta: {
          title: '商品详情',
          keepAlive: false
        }
      },
      {
        path: '/mall/cart',
        name: 'mall-cart',
        component: () => import('./view/mall/cart/index.vue'),
        meta: {
          title: '购物车',
          keepAlive: true,
          hasTabbar: true
        },
      },
      {
        path: '/mall/order-confirm',
        name: 'mall-order-confirm',
        component: () => import('./view/mall/order/confirm.vue'),
        meta: {
          title: '确认订单',
          keepAlive: false,
        },
      },
      {
        path: '/mall/pay-success',
        name: 'mall-paysuccess',
        component: () => import('./view/mall/order/pay-success.vue'),
        meta: {
          title: '支付成功',
          keepAlive: false
        },
      },
      {
        path: '/mall/order-list',
        name: 'mall-orderlist',
        component: () => import('./view/mall/order/list.vue'),
        meta: {
          title: '我的订单',
          keepAlive: true
        },
      },
      {
        path: '/mall/order-search',
        name: 'mall-ordersearch',
        component: () => import('./view/mall/order/search.vue'),
        meta: {
          title: '订单搜索',
          keepAlive: false
        },
      },
      {
        path: '/mall/order-detail',
        name: 'mall-ordersdetail',
        component: () => import('./view/mall/order/detail.vue'),
        meta: {
          title: '订单详情',
          keepAlive: false
        },
      },
      {
        path: '/mall/exchange-detail',
        name: 'mall-exchangedetail',
        component: () => import('./view/mall/order/exchange-detail.vue'),
        meta: {
          title: '订单详情',
          keepAlive: false
        },
      },
      {
        path: '/mall/order-logistics',
        name: 'mall-logistics',
        component: () => import('./view/mall/order/logistics.vue'),
        meta: {
          title: '物流详情',
          keepAlive: false
        },
      },
      {
        path: '/mall/coupon-list',
        name: 'mall-coupon-list',
        component: () => import('./view/mall/coupon/list.vue'),
        meta: {
          title: '优惠券',
          keepAlive: false
        }
      }
      
    ]
  }
]

routes.forEach((route) => {
  route.path = route.path || '/' + (route.name || '')
})

const router = new Router({
  mode: 'history',
  routes,
})

const allowList = ['login', 'agreement', 'modal', 'userAgreement'] // no redirect allowList
const loginRoutePath = '/login'
const defaultRoutePath = '/'

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  // next()
  /* 是否登录 */
  if (storage.get(ACCESS_TOKEN)) {
    if (to.path === loginRoutePath) {
      if (from.path === '/') {
        store.commit('SET_CITY', null)
        storage.remove(CITY)
      }
      store.commit(CLEAR_KEEP_ALIVE)
      next({ path: defaultRoutePath })
    } else {
      if (from.path === '/') {
        store.commit('SET_CITY', null)
        storage.remove(CITY)
      }
      // check login userInfo is null
      if (!store.getters.userInfo) {
        store.commit(CLEAR_KEEP_ALIVE)
        // request login userInfo
        store
          .dispatch('GetInfo')
          .then((res) => {
            // 请求带有 redirect 重定向时，登录自动重定向到该地址
            const redirect = decodeURIComponent(from.query.redirect || to.path)
            if (to.path === redirect) {
              // set the replace: true so the navigation will not leave a history record
              next({ ...to, replace: true })
            } else {
              // 跳转到目的路由
              next({ path: redirect })
            }
          })
          .catch((err) => {
            console.log(err)
            // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
            store.dispatch('Logout').then(() => {
              next({ path: loginRoutePath, query: { redirect: to.fullPath } })
            })
          })
      } else {
        if (to.meta.keepAlive) {
          store.commit(ADD_KEEP_ALIVE, [
            to.name
          ])
        }
        next()
      }
    }
  } else {
    if (allowList.includes(to.name)) {
      // 在免登录名单，直接进入
      next()
    } else {
      if (to.query.signature && to.query.encrypt && to.query.source) {
        store.dispatch('SSOLogin', to.query)
          .then(() => {
            window.location.href = '/'
          })
          .catch(() => {
            next({ path: loginRoutePath, query: { redirect: to.fullPath } })
          })
        return
      }
      next({ path: loginRoutePath, query: { redirect: to.fullPath } })
    }
  }
})

export { router }
